import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Button, Popup, Table } from '_atoms';
import { Context } from '_templates/AnalyticsDashboard/analytics-dashboard';
import { candidateMap } from '_templates/utils';
import { BUTTON_TEXT, POPUP_BODY, POPUP_LABELS } from 'constants/text';
import { analyticWidgets } from 'actions/analyticActions';
import { ReactComponent as Upload } from 'dist/icons/upload.svg';
import Date from 'dist/images/common/icons/graph.svg';
import { AnalyticsEmpty } from '../AnalyticsDashboard/AnalyticsEmpty/analytics-empty';
import { COLUMNS, OPTIONS } from './constants';
import './style.scss';

export const AnalyticsCandidateInfo = () => {
  const { appliedFilters } = useContext(Context);
  const [candidates, setCandidates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState(OPTIONS);
  const [exportPopupOpen, setExportPopupOpen] = useState(false);
  const [successPopupOpen, setSuccessPopupOpen] = useState(false);

  const updateCandidatesTableParams = useCallback(params => setOptions(p => ({ ...p, ...params })), [setOptions]);

  const exportExcel = () => {
    analyticWidgets('candidates/export/xlsx', options).then(() => {
      setSuccessPopupOpen(true);
      setExportPopupOpen(false);
    });
  };

  const getCandidates = opts => {
    setLoading(true);
    analyticWidgets(
      'candidates',
      { ...opts, sorting: [opts.sorting] },
      null,
      `?limit=${opts.limit.value}&offset=${opts.offset}`
    )
      .then(resp => {
        updateCandidatesTableParams({ itemsCount: resp.count, next: !!resp.next, prev: !!resp.previous });
        setCandidates(candidateMap(resp));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getCandidates({ ...appliedFilters, ...options });
  }, [options.offset, options.limit, options.sorting, appliedFilters]);

  if (!candidates.length) {
    return (
      <AnalyticsEmpty
        text="Please try different period or filters"
        title="There are no results for the specified parameters"
        className="shadow"
        img={Date}
      />
    );
  }

  return (
    <>
      <Table
        rowKey="uuid"
        className="analytics-candidates"
        cardTitle="Candidates assigned to jobs"
        cardCount={options.itemsCount}
        extra={
          <Button onClick={() => setExportPopupOpen(true)} icon={<Upload />} className="export-excel">
            {BUTTON_TEXT.EXPORT_EXCEL}
          </Button>
        }
        columns={COLUMNS(options.sorting, sorting => updateCandidatesTableParams({ sorting }))}
        data={candidates}
        count={candidates.length}
        limit={options.limit}
        page={options.page}
        offset={options.offset}
        updateParams={setOptions}
        itemsCount={options.itemsCount}
        loading={loading}
      />
      <Popup
        open={exportPopupOpen}
        title={POPUP_LABELS.EXPORT_EXCEL}
        cancelButtonProps={{ onClick: () => setExportPopupOpen(false), children: BUTTON_TEXT.GO_BACK }}
        okButtonProps={{ onClick: exportExcel, children: BUTTON_TEXT.SEND_TO_EMAIL }}
      >
        {POPUP_BODY.EXPORT_EXCEL}
      </Popup>
      <Popup
        open={successPopupOpen}
        title={POPUP_LABELS.SUCCESS}
        okButtonProps={{ onClick: () => setSuccessPopupOpen(false), children: BUTTON_TEXT.OK }}
      >
        {POPUP_BODY.FILE_SENT}
      </Popup>
    </>
  );
};
