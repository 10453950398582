import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import connect from 'react-redux/es/connect/connect';
import { Form } from 'antd';
import cn from 'classnames';
import { FiltersJob } from '_organisms';
import { EMPTY_FILTERS_VALUES, RESET_FILTERS_VALUES } from '_organisms/FiltersJob/constants';
import { Button, BUTTON_TYPES, Table, Tooltip } from '_atoms';
import { Typography, TYPOGRAPHY_WEIGHT } from '_atoms/Typography';
import { TableMobile } from '_atoms/Table/Table';
import { MixpanelService } from 'helpers/Mixpanel';
import * as actions from 'actions';
import { changeJobFilter } from 'store/reducers/jobReducer';
import { getUser } from 'store/selectors/authorizeSelectors';
import { getCompanies, getLocations } from 'store/selectors/commonSelectors';
import { getJobListFilters } from 'store/selectors/jobSelectors';
import history from 'helpers/history';
import { useQuery } from 'helpers/useLocation';
import { ACTIONS, Can, SUBJECTS } from 'permission';
import { BUTTON_TEXT } from 'constants/text';
import { ROUTES } from 'router/constants';
import { ReactComponent as Vector } from 'dist/icons/Vector1.svg';
import { ReactComponent as Info } from 'dist/icons/info.svg';
import { filtersMapper, defaultFilters } from './utils';
import { TEXT, INITIAL_VALUES, COLUMNS, expandedRowRender, EMPTY_OPTIONS } from './constants';
import './style.scss';

const Jobs = props => {
  const [form] = Form.useForm();
  const location = useLocation();
  const query = useQuery();

  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    ...EMPTY_FILTERS_VALUES,
    ...defaultFilters(props.user),
    ...props.filter,
    ...query,
  });
  const [tableOptions, setTableOptions] = useState(INITIAL_VALUES);
  const [jobsList, setJobsList] = useState([]);
  const [jobsInfo, setJobsInfo] = useState({ activeOpenings: 0, waitingForEntry: 0 });
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  useEffect(() => {
    props.getAllCompanies();
    const mixpanel = new MixpanelService();
    mixpanel.track('View Page.Jobs List');
  }, []);

  useEffect(() => {
    if (Object.keys(query).length) {
      history.replace(location.pathname);
    }
  }, [query]);

  const handleChangeDate = (field, item) => {
    if (field === 'page') {
      setTableOptions(s => ({ ...s, page: item, offset: (item - 1) * s.limit.value }));
    } else if (field === 'limit') {
      setTableOptions(s => ({ ...s, limit: item, offset: 0, page: 1 }));
    } else {
      setTableOptions(s => ({ ...s, [field]: item }));
    }
  };

  const setSorting = sort => handleChangeDate('sorting', sort);

  const onApplyFilters = () => {
    if (tableOptions.offset === 0 && tableOptions.page === 1) {
      getJobs();
    } else {
      setTableOptions(s => ({ ...s, offset: 0, page: 1 }));
    }
  };

  const resetFilter = () => {
    form.setFields(RESET_FILTERS_VALUES);
    props.changeJobFilter({});
    setFilters(EMPTY_FILTERS_VALUES);
    onApplyFilters();
  };

  const getJobs = (values = form.getFieldsValue()) => {
    props.changeJobFilter(values);
    setLoading(true);
    actions.getListJobs({ ...filtersMapper(values, tableOptions) }, 'v2').then(resp => {
      setLoading(false);
      setTableOptions(s => ({ ...s, itemsCount: resp.count }));
      setJobsList(resp.results.jobs);
      setJobsInfo({
        activeOpenings: resp.results.active_openings_count,
        waitingForEntry: resp.results.waiting_for_entry_openings_count,
      });
      setExpandedRowKeys([]);
    });
  };

  const onExpand =
    ({ uuid }) =>
    () => {
      if (!expandedRowKeys.includes(uuid)) setExpandedRowKeys(rows => [...rows, uuid]);
      else setExpandedRowKeys(rows => rows.filter(r => r !== uuid));
    };

  useEffect(getJobs, [tableOptions.sorting, tableOptions.limit, tableOptions.offset, tableOptions.page]);

  return (
    <div className="wrapper-content jobs-list">
      <FiltersJob
        form={form}
        onFinish={onApplyFilters}
        onReset={resetFilter}
        initialValues={filters}
        loading={loading}
      />
      <Table
        rowKey="uuid"
        className="table-jobs"
        cardTitle={TEXT.TITLE}
        subtitle={<JobsSubTitle activeOpenings={jobsInfo.activeOpenings} waitingForEntry={jobsInfo.waitingForEntry} />}
        cardCount={tableOptions.itemsCount}
        emptyComponent={<JobsEmpty />}
        extra={
          <Can I={ACTIONS.CREATE} a={SUBJECTS.JOB}>
            <Button type={BUTTON_TYPES.PRIMARY} onClick={() => history.push(ROUTES.JOBS_CREATE)}>
              {BUTTON_TEXT.ADD_JOB}
            </Button>
          </Can>
        }
        columns={COLUMNS(tableOptions.sorting, setSorting)}
        data={jobsList}
        tooltipInfo={TEXT.TOOLTIP}
        count={jobsList.length}
        limit={tableOptions.limit}
        page={tableOptions.page}
        offset={tableOptions.offset}
        updateParams={handleChangeDate}
        itemsCount={tableOptions.itemsCount}
        loading={loading}
        expandable={{
          expandedRowKeys,
          expandedRowRender,
          expandIcon: ({ expanded, record }) => (
            <>
              <Vector className={cn('expanded-icon', { expanded })} onClick={onExpand(record)} />
              <TableMobile data={[record]} columns={COLUMNS(tableOptions.sorting, setSorting)} empty={<JobsEmpty />} />
              <Button type={BUTTON_TYPES.TEXT} onClick={onExpand(record)} className="expanded-icon-mobile">
                {expanded ? 'Hide openings' : 'Show openings'}
              </Button>
            </>
          ),
        }}
        showCardFooter={jobsList.length}
      />
    </div>
  );
};

function mapStateToProps(store) {
  return {
    filter: getJobListFilters(store),
    companies: getCompanies(store),
    locations: getLocations(store),
    user: getUser(store),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getAllCompanies: bindActionCreators(actions.getAllCompanies, dispatch),
    getListLocations: bindActionCreators(actions.getListLocations, dispatch),
    changeJobFilter: bindActionCreators(changeJobFilter, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Jobs);

export const JobsEmpty = () => (
  <div className="empty-template">
    <img src={EMPTY_OPTIONS.image} alt="" />
    <Typography.Title2 weight={TYPOGRAPHY_WEIGHT.BOLD}>{EMPTY_OPTIONS.title}</Typography.Title2>
    <Typography.Title3 weight={TYPOGRAPHY_WEIGHT.BOLD}>{EMPTY_OPTIONS.subtitle}</Typography.Title3>
  </div>
);

const JobsSubTitle = ({ activeOpenings, waitingForEntry }) => (
  <div className="jobs-list-subtitle">
    <Typography.Title3>
      Active openings
      <Tooltip label='The number of openings in the "Open" and "Waiting for entry" statuses'>
        <Info />
      </Tooltip>
      <span>{activeOpenings}</span>
    </Typography.Title3>
    <Typography.Title3>
      Waiting for entry<span>{waitingForEntry}</span>
    </Typography.Title3>
  </div>
);
