import { useCallback } from 'react';
import { ASSIGNED_CONFIG, INTERACTED_CONFIG, JOB_OFFERS_CONFIG, TOTAL_UNIQUE_CANDIDATE_CONFIG } from './constans';
import './styles.scss';

export const AnalyticsFilterHelper = resp =>
  resp.map(item => ({
    value: item.uuid,
    label: item.company_name ? `${item.name}  (${item.company_name})` : item.name,
  }));

export const rejectionNumberHelper = resp =>
  resp.map(item => ({
    name: item.type,
    value: item.rejections,
    data: [
      { name: 'Rejection:', data: item.rejections },
      { name: 'Absolute conversion:', data: `${item.absolute_conversion}%` },
    ],
  }));

export const rejectionReasonHelper = resp =>
  resp.map(item => ({
    type: item.type,
    reasons: item.reasons
      .filter(reason => !!reason.rejections)
      .sort((a, b) => b.rejections - a.rejections)
      .map(option => ({
        name: option.reason,
        value: option.rejections,
        data: [
          { name: 'Rejection:', data: option.rejections },
          { name: 'Absolute conversion:', data: `${option.absolute_conversion}%` },
        ],
      })),
  }));

export const transitionStepHelper = resp => {
  const result = { options: [], empty: false };

  result.empty = !resp.steps_transition.filter(item => !!item.candidates_count).length;
  result.options = resp.steps_transition
    .filter(item => !!item.candidates_count && item.type !== 'Technical Interview')
    .map(option => ({
      ...option,
      relative_conversion: option.relative_conversion ? `${option.relative_conversion}%` : '-',
      absolute_conversion: option.absolute_conversion ? `${option.absolute_conversion}%` : '-',
    }));
  result.options.unshift({
    type: 'Interacted',
    candidates_count: resp.interacted.new_interaction + resp.interacted.other_interaction,
  });
  return result;
};

export const passingStepHelper = resp =>
  resp.map((item, index) => ({ label: item.type, value: item.type, data: item.duration_hours, index }));

export const interactedDataMapper = data => ({
  ...INTERACTED_CONFIG,
  total: data.new_interaction + data.other_interaction,
  extra: data.new_added,
  extra_description: 'New added candidates',
  options:
    data.new_interaction + data.other_interaction
      ? [{ label: 'New added candidates', value: data.new_interaction }]
      : [],
  loading: false,
});

export const assignedDataMapper = (data, { jobs, services }) => {
  const simpleView = (!jobs.length && services.length !== 1) || (jobs.length > 1 && services.length > 1);

  return {
    ...ASSIGNED_CONFIG,
    total: data.candidates,
    simpleView,
    options:
      jobs.length === 1
        ? []
        : simpleView
        ? [
            { label: 'Services', value: data.services },
            { label: 'Jobs', value: data.jobs.length },
          ]
        : data.jobs.map(job => ({ label: job.name, value: job.candidates_number })),
    loading: false,
  };
};

export const jobOffersDataMapper = data => ({
  ...JOB_OFFERS_CONFIG,
  total: data.total,
  options: [
    { label: 'Rejected', value: data.rejected },
    { label: 'Job started', value: data.job_started },
    { label: 'Waiting for feedback', value: data.waiting_feedback },
  ],
  loading: false,
});

export const totalUniqueCandidatesDataMapper = data => [
  {
    ...TOTAL_UNIQUE_CANDIDATE_CONFIG,
    total: data.candidates,
    options: [
      { label: 'Services', value: data.services },
      { label: 'Jobs', value: data.jobs.length },
    ],
    loading: false,
  },
];

export const useAnalyticsTooltip = (options, type) =>
  useCallback(
    context => {
      const { chart, tooltip } = context;
      let tooltipEl = chart.canvas.parentNode.querySelector('div.tooltipDesign');
      let tooltipUl = chart.canvas.parentNode.querySelector('ul');
      if (!tooltipEl || !tooltipUl) {
        tooltipEl = document.createElement('div');
        tooltipEl.classList.add('tooltipDesign');
        tooltipUl = document.createElement('ul');
        tooltipUl.classList.add('tooltipUl');

        tooltipEl.appendChild(tooltipUl);
        chart.canvas.parentNode.appendChild(tooltipEl);
      }

      if (tooltip.opacity === 0) {
        tooltipEl.style.display = 'none';
        return;
      }

      if (tooltip.body) {
        let tooltipOptions = options;
        if (tooltip.body[0].lines[0].includes('Step time')) tooltipOptions = options.bar;
        if (tooltip.body[0].lines[0].includes('Total time')) tooltipOptions = options.line;
        let values;
        if (type === 'doughnut') {
          values = tooltipOptions.find(option => option.name === tooltip.dataPoints[0].label).data;
        } else {
          values = tooltipOptions.find(option => option.name === tooltip.title[0].replace(',', ' ')).data;
        }

        const tooltipLi = document.createElement('li');
        const leftBlock = document.createElement('div');
        leftBlock.classList.add('left');
        const rightBlock = document.createElement('div');
        rightBlock.classList.add('right');
        tooltipLi.appendChild(leftBlock);
        tooltipLi.appendChild(rightBlock);
        values.forEach(option => {
          const leftSpan = document.createElement('span');
          const leftText = document.createTextNode(option.name);
          leftSpan.appendChild(leftText);
          leftBlock.appendChild(leftSpan);
          const rightSpan = document.createElement('span');
          const rightText = document.createTextNode(option.data);
          rightSpan.appendChild(rightText);
          rightBlock.appendChild(rightSpan);
        });

        while (tooltipUl.firstChild) {
          tooltipUl.firstChild.remove();
        }

        tooltipUl.appendChild(tooltipLi);
        tooltipEl.style.display = 'block';

        const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
        if (type === 'doughnut') {
          tooltipEl.style.left = `${positionX + 190}px`;
          tooltipEl.style.top = `${positionY + 65}px`;
        } else {
          tooltipEl.style.left = `${positionX + tooltip.caretX}px`;
          tooltipEl.style.top = `${positionY - 110 + tooltip.caretY}px`;
        }
      }
    },
    [options]
  );
